<template>
  <section>
    <div class="breadcrumb mb-6">
      <breadcrumb
        title="Propostas"
        actualPage="Visualizar"
        previousPage="propostas-seguradora"
        previousPageTitle="Lista de propostas"
      />
    </div>

    <template v-if="proposta.Broker">
      <div class="w-full content p-4 visualizar-proposta-tabs">
        <vs-tabs color="warning">
          <vs-tab label="Resumo da Proposta" @click="loadProposta()">
            <vs-row>
              <vs-col
                vs-type="flex"
                vs-justify="end"
                vs-align="end"
                vs-w="12"
                v-if="proposta.ProposalTypeId == 2"
              >
                <visualizar-alteracoes
                  :changed="diffEndosso.length > 0"
                  :showDesfazer="false"
                  :objectDiff="diffEndosso"
                  :responsavel="responsavelDataUpdateEndosso"
                />
              </vs-col>
            </vs-row>

            <vs-row>
              <vs-col :vs-lg="proposta.ProposalTypeId == 2 ? 3 : 4" vs-sm="12">
                <p class="flex font-semibold">Nº da proposta</p>
                <vs-input
                  disabled
                  class="w-full"
                  v-model="proposta.Number"
                  size="large"
                />
              </vs-col>

              <vs-col
                v-if="proposta.ProposalTypeId == 2"
                :vs-lg="proposta.ProposalTypeId == 2 ? 3 : 4"
                vs-sm="12"
              >
                <p class="flex font-semibold">Nº da apólice</p>
                <vs-input
                  v-if="(proposta.Policy || {}).Number"
                  disabled
                  class="w-full"
                  v-model="(proposta.Policy || {}).Number"
                  size="large"
                />
                <vs-input
                  v-else
                  disabled
                  class="w-full"
                  value="Sem número"
                  size="large"
                />
              </vs-col>

              <vs-col :vs-lg="proposta.ProposalTypeId == 2 ? 3 : 4" vs-sm="12">
                <p class="flex font-semibold">Data de cadastro</p>
                <vs-input
                  disabled
                  class="w-full"
                  v-model="CreateDate"
                  size="large"
                />
              </vs-col>

              <vs-col :vs-lg="proposta.ProposalTypeId == 2 ? 3 : 4" vs-sm="12">
                <p class="flex font-semibold">Status</p>
                <vs-input
                  disabled
                  class="w-full"
                  v-model="statusName"
                  size="large"
                />
              </vs-col>
            </vs-row>

            <vs-row>
              <vs-col vs-lg="9" vs-sm="12">
                <p class="flex font-semibold">Corretora</p>
                <vs-input
                  disabled
                  class="w-full"
                  size="large"
                  v-model="proposta.Broker.Name"
                />
              </vs-col>

              <vs-col vs-lg="3" vs-sm="12">
                <p class="flex font-semibold">CNPJ da Corretora</p>
                <vs-input
                  disabled
                  size="large"
                  :value="proposta.Broker.CpfCnpj | maskCnpj"
                />
              </vs-col>
            </vs-row>

            <vs-row>
              <vs-col vs-lg="9" vs-sm="12">
                <p class="flex font-semibold">Tomador</p>
                <vs-input
                  disabled
                  :value="proposta.PolicyHolderBranch ? proposta.PolicyHolderBranch.Name : proposta.PolicyHolder.Name"
                  class="w-full"
                  size="large"
                />
                <router-link
                  :to="{
                    name: 'detalhe-tomador',
                    params: { tomadorUniqueId: proposta.PolicyHolder.UniqueId }
                  }"
                  target="_blank"
                >
                  <vs-button color="primary" type="flat">
                    <span class="font-semibold">
                      Visualizar cadastro completo
                    </span>
                  </vs-button>
                </router-link>
              </vs-col>

              <vs-col vs-lg="3" vs-sm="12">
                <p class="flex font-semibold">CNPJ</p>

                <vs-input
                  disabled
                  size="large"
                  :value="proposta.PolicyHolderBranch ? proposta.PolicyHolderBranch.CpfCnpj : proposta.PolicyHolder.CpfCnpj | maskCnpj"
                />
              </vs-col>
            </vs-row>

            <vs-row>
              <vs-col vs-lg="9" vs-sm="12">
                <p class="flex font-semibold">Segurado</p>
                <vs-input
                  disabled
                  class="w-full"
                  v-model="proposta.Insured.Name"
                  size="large"
                />

                <router-link
                  :to="{
                    name: 'detalhe-segurado',
                    params: { seguradoUniqueId: proposta.Insured.UniqueId }
                  }"
                  target="_blank"
                >
                  <vs-button color="primary" type="flat">
                    <span class="font-semibold">
                      Visualizar cadastro completo
                    </span>
                  </vs-button>
                </router-link>
              </vs-col>
              <vs-col vs-lg="3" vs-sm="12">
                <p class="flex font-semibold">CNPJ</p>

                <vs-input
                  disabled
                  size="large"
                  :value="proposta.Insured.CpfCnpj | maskCnpj"
                />
              </vs-col>
            </vs-row>

            <vs-row
              v-for="(item, index) in proposta.Beneficiaries || []"
              :key="index"
            >
              <vs-col vs-lg="6" vs-sm="12">
                <p class="flex font-semibold">Beneficiário</p>
                <vs-input
                  disabled
                  class="w-full"
                  size="large"
                  v-model="item.Name"
                />
              </vs-col>

              <vs-col vs-lg="3" vs-sm="12">
                <p class="flex font-semibold">
                  {{ $utils.isValidCpf(item.CpfCnpj) ? "CPF" : "CNPJ" }}
                </p>
                <vs-input
                  disabled
                  size="large"
                  :value="$utils.maskDocument(item.CpfCnpj)"
                />
              </vs-col>

              <vs-col vs-lg="3" vs-sm="12">
                <p class="flex font-semibold">% do seguro</p>
                <vs-input
                  disabled
                  size="large"
                  :value="item.PercentageAmountValue"
                />
              </vs-col>
            </vs-row>

            <hr class="mt-10 opacity-25" />

            <vs-row>
              <vs-col
                :class="
                  (propostaFinancialData || {}).PrincipalModality
                    ? 'w-1/6'
                    : 'w-1/5'
                "
              >
                <p class="font-semibold">Modalidade Principal</p>
                <vs-input
                  v-if="proposta.Modality"
                  disabled
                  class="w-full"
                  v-model="proposta.Modality.Name"
                  :value="
                    proposta.Modality.Name +
                      '(' +
                      proposta.ModalityGroup.Name +
                      ')'
                  "
                  size="large"
                />
                <vs-input
                  v-else
                  disabled
                  class="w-full"
                  value="Sem modalidade
                  "
                  size="large"
                />
              </vs-col>

              <vs-col
                :class="
                  (propostaFinancialData || {}).PrincipalModality
                    ? 'w-1/6'
                    : 'w-1/5'
                "
              >
                <p class="flex font-semibold">Importância Segurada</p>
                <currency-input
                  disabled="true"
                  class="w-full vs-inputx vs-input--input large hasValue"
                  size="large"
                  v-model="proposta.InsuredAmountValue"
                />
              </vs-col>

              <vs-col
                :class="
                  (propostaFinancialData || {}).PrincipalModality
                    ? 'w-1/6'
                    : 'w-1/5'
                "
              >
                <p class="flex font-semibold">Início da vigência</p>
                <vs-input
                  disabled
                  class="w-full"
                  v-model="StartDate"
                  size="large"
                />
              </vs-col>

              <vs-col
                :class="
                  (propostaFinancialData || {}).PrincipalModality
                    ? 'w-1/6'
                    : 'w-1/5'
                "
              >
                <p class="flex font-semibold">Prazo (dias)</p>
                <vs-input
                  disabled
                  class="w-full"
                  v-model="proposta.DeadlineDays"
                  size="large"
                />
              </vs-col>

              <vs-col
                :class="
                  (propostaFinancialData || {}).PrincipalModality
                    ? 'w-1/6'
                    : 'w-1/5'
                "
              >
                <p class="flex font-semibold">Fim da vigência</p>
                <vs-input
                  disabled
                  class="w-full"
                  v-model="EndDate"
                  size="large"
                />
              </vs-col>

              <vs-col
                class="w-1/6"
                v-if="(propostaFinancialData || {}).PrincipalModality"
              >
                <p class="font-semibold">Prêmio</p>
                <currency-input
                  disabled="true"
                  class="w-full vs-inputx vs-input--input large hasValue"
                  size="large"
                  v-model="(propostaFinancialData || {}).PrincipalModality"
                />
              </vs-col>
            </vs-row>

            <vs-row
              v-if="
                proposta.ComplementaryModality &&
                  proposta.ComplementaryModality.Id > 0
              "
            >
              <vs-col
                :class="
                  (propostaFinancialData || {}).PrincipalModality
                    ? 'w-1/6'
                    : 'w-1/5'
                "
              >
                <p class="font-semibold">Modalidade Secundária</p>
                <vs-input
                  disabled
                  class="w-full"
                  v-model="proposta.ComplementaryModality.Name"
                  size="large"
                />
              </vs-col>

              <vs-col
                :class="
                  (propostaFinancialData || {}).PrincipalModality
                    ? 'w-1/6'
                    : 'w-1/5'
                "
              >
                <p class="flex font-semibold tooltip-info">
                  <span>Importância Segurada</span>
                  <vx-tooltip text="Modalidade secundária" position="right">
                    <i class="material-icons pl-2">info</i>
                  </vx-tooltip>
                </p>
                <currency-input
                  disabled="true"
                  class="w-full vs-inputx vs-input--input large hasValue"
                  size="large"
                  v-model="proposta.ComplementaryInsuredAmountValue"
                />
              </vs-col>

              <vs-col
                :class="
                  (propostaFinancialData || {}).PrincipalModality
                    ? 'w-1/6'
                    : 'w-1/5'
                "
              >
                <p class="flex font-semibold tooltip-info">
                  <span>Início da vigência</span>
                  <vx-tooltip text="Modalidade secundária" position="right">
                    <i class="material-icons pl-2">info</i>
                  </vx-tooltip>
                </p>
                <vs-input
                  disabled
                  class="w-full"
                  v-model="StartDateComplementary"
                  size="large"
                />
              </vs-col>

              <vs-col
                :class="
                  (propostaFinancialData || {}).PrincipalModality
                    ? 'w-1/6'
                    : 'w-1/5'
                "
              >
                <p class="flex font-semibold tooltip-info">
                  <span>Prazo (dias)</span>
                  <vx-tooltip text="Modalidade secundária" position="right">
                    <i class="material-icons pl-2">info</i>
                  </vx-tooltip>
                </p>
                <vs-input
                  disabled
                  class="w-full"
                  v-model="proposta.ComplementaryDeadlineDays"
                  size="large"
                />
              </vs-col>

              <vs-col
                :class="
                  (propostaFinancialData || {}).PrincipalModality
                    ? 'w-1/6'
                    : 'w-1/5'
                "
              >
                <p class="flex font-semibold tooltip-info">
                  <span>Fim da vigência</span>
                  <vx-tooltip text="Modalidade secundária" position="right">
                    <i class="material-icons pl-2">info</i>
                  </vx-tooltip>
                </p>
                <vs-input
                  disabled
                  class="w-full"
                  v-model="EndDateComplementary"
                  size="large"
                />
              </vs-col>

              <vs-col
                class="w-1/6"
                v-if="(propostaFinancialData || {}).PrincipalModality"
              >
                <p class="font-semibold">Prêmio</p>
                <currency-input
                  disabled="true"
                  class="w-full vs-inputx vs-input--input large hasValue"
                  size="large"
                  v-model="(propostaFinancialData || {}).ComplementryModality"
                />
              </vs-col>
            </vs-row>

            <template
              v-if="
                proposta.ProposalCoverages &&
                  proposta.ProposalCoverages.length > 0
              "
            >
              <vs-row
                v-for="(item, indexCoverge) in proposta.ProposalCoverages"
                :key="`coverge-${indexCoverge}`"
              >
                <vs-col
                  :class="
                    (propostaFinancialData || {}).PrincipalModality
                      ? 'w-1/6'
                      : 'w-1/5'
                  "
                >
                  <p class="flex font-semibold">Coberturas Adicionais</p>
                  <vs-input
                    disabled
                    class="w-full"
                    :value="item.Coverage.Name"
                    size="large"
                  />
                </vs-col>

                <vs-col
                  :class="
                    (propostaFinancialData || {}).PrincipalModality
                      ? 'w-1/6'
                      : 'w-1/5'
                  "
                >
                  <p class="font-semibold">Valor</p>
                  <currency-input
                    disabled="true"
                    class="w-full vs-inputx vs-input--input large hasValue"
                    size="large"
                    v-model="proposta.InsuredAmountValue"
                  />
                </vs-col>

                <vs-col
                  :class="
                    (propostaFinancialData || {}).PrincipalModality
                      ? 'w-1/6'
                      : 'w-1/5'
                  "
                >
                  <p class="font-semibold">Início da vigência</p>

                  <vs-input
                    disabled
                    class="w-full"
                    :value="$utils.dateToDDMMYYYY(item.StartDate)"
                    size="large"
                  />
                </vs-col>

                <vs-col
                  :class="
                    (propostaFinancialData || {}).PrincipalModality
                      ? 'w-1/6'
                      : 'w-1/5'
                  "
                >
                  <p class="font-semibold">Prazo (dias)</p>

                  <vs-input
                    disabled
                    class="w-full"
                    :value="$utils.diffDates(item.EndDate, item.StartDate)"
                    size="large"
                  />
                </vs-col>

                <vs-col
                  :class="
                    (propostaFinancialData || {}).PrincipalModality
                      ? 'w-1/6'
                      : 'w-1/5'
                  "
                >
                  <p class="font-semibold">Fim da vigência</p>

                  <vs-input
                    disabled
                    class="w-full"
                    :value="$utils.dateToDDMMYYYY(item.EndDate)"
                    size="large"
                  />
                </vs-col>

                <vs-col
                  class="w-1/6"
                  v-if="(propostaFinancialData || {}).PrincipalModality"
                >
                  <p class="font-semibold">Prêmio</p>
                  <currency-input
                    disabled="true"
                    class="w-full vs-inputx vs-input--input large hasValue"
                    size="large"
                    :value="
                      (
                        ((propostaFinancialData || {}).Coverages || []).find(
                          x => x.CoverageId == item.Id
                        ) || {}
                      ).CoveragePremium
                    "
                  />
                </vs-col>
              </vs-row>
            </template>
            <template v-if="endosso && endosso.Id">
              <hr class="my-12 opacity-25" />
              <h4 class="mt-5">Endosso</h4>

              <vs-row>
                <vs-col class="w-1/4">
                  <p class="font-semibold">Número</p>
                  <vs-input
                    disabled
                    class="w-full"
                    v-model="endosso.Number"
                    size="large"
                  />
                </vs-col>

                <vs-col class="w-1/4">
                  <p class="font-semibold">Motivo</p>
                  <vs-input
                    disabled
                    class="w-full"
                    v-model="endosso.ReasonEndorsementRequest.Name"
                    size="large"
                  />
                </vs-col>

                <vs-col class="w-1/2">
                  <p class="font-semibold">Tipo</p>
                  <span
                    class="text-lg inline-flex pb-3"
                    v-for="(text, index) in endosso.ChangeTypes.map(
                      x => x.Description
                    )"
                    :key="index"
                  >
                    <i v-if="text" class="onpoint-check-circle my-auto mr-5"></i>
                    <p v-if="text" v-html="text"></p>
                  </span>
                </vs-col>
              </vs-row>
              <vs-row v-if='restituitionPermition'>               
                <configuracao-restituicao  :proposta='proposta'/>
              </vs-row>
            </template>

            <hr class="my-12 opacity-25" />

            <dados-financeiros
              :blockInputs="blockInputs"
              :compareChanges="compareChanges"
              :StringObservations="StringObservations"
              class="mt-10"
              @saveProposal="atualizarProposta(true)"
              :permissoesCocorretagemMapfre="permissoesCocorretagemMapfre"
            />

            <CosseguroMapfre v-if="podeExibirCosseguro"  @tipoCosseguroMapfre="popularTipoCosseguroMapfre" @cosseguroMapfre="popularCosseguroMapfre"/>

            <ResseguroMapfre v-if="podeExibirResseguro" @tipoResseguroMapfre="popularTipoResseguroMapfre" @resseguroMapfre="popularResseguroMapfre"/>

            <template v-if="proposalEndorsement && proposalEndorsement.length">
              <hr class="my-12 opacity-25" />
              <h4 class="mt-5">Objeto do endosso</h4>
              <vs-row
                v-for="(object, index) in proposalEndorsement"
                :key="`endosso-${index}`"
              >
                <vs-col vs-w="12" class="quill-container">
                  <!-- <p>
                    <b>{{ object.Label }}</b>
                  </p> -->
                  <jodit-editor
                    :config="joditConfig"
                    class="mt-3"
                    v-model="object.Value"
                    @input="setValueContractualTerms($event, object)"
                  />
                </vs-col>
              </vs-row>
            </template>

            <template
              v-if="
                (particularClauses &&
                  particularClauses.length &&
                  !changeable) ||
                  (proposalClauses && proposalClauses.length)
              "
            >
              <hr class="mt-10 px-8 opacity-25" />
              <h4 class="mt-5">Clausulas Particulares</h4>

              <vs-row v-if="!blockInputs && !changeable">
                <vs-col vs-type="flex" vs-justify="flex-end">
                  <vs-button
                    color="primary"
                    v-if="!blockInputs"
                    @click="showParticularClausesDialog()"
                  >
                    <span class="font-semibold">
                      Adicionar / Alterar Clausulas Particulares
                    </span>
                  </vs-button>
                </vs-col>
              </vs-row>

              <vs-row
                v-for="(object, index) in proposalClauses"
                :key="`clausules-${index}`"
              >
                <vs-row class="mb-5">
                  <vs-switch
                    v-model="object.EnableToProposal"
                    @click.prevent="InsertObjets(object)"
                  ></vs-switch>
                  <label class="ml-2"> Habilitar na Proposta</label>
                </vs-row>
                <vs-col vs-w="12" class="quill-container">
                  <p>
                    <b>{{ object.Label }}</b>
                  </p>
                  <jodit-editor
                    class="mt-3"
                    :config="joditConfig"
                    v-model="object.Value"
                    @input="setValueContractualTerms($event, object)"
                  />
                  <!--
                  <ckeditor

                    :read-only="
                      particularClausesEdit ||
                      checkQuillDisabled(object.ContractualTermTypeId)
                    "

                  ></ckeditor> -->
                </vs-col>
              </vs-row>
            </template>

            <hr class="mt-12 opacity-25" />

            <vs-tabs v-if="proposalTerms.length > 0">
              <vs-tab
                v-for="(object, index) in proposalTerms"
                :key="index"
                :label="object.Label"
              >
                <vs-row class="mb-5">
                  <vs-switch
                    v-model="object.EnableToProposal"
                    @click.prevent="InsertObjets(object)"
                  ></vs-switch>
                  <label class="ml-2"> Habilitar na Proposta</label>
                </vs-row>
                <vs-col vs-w="12" class="quill-container">
                  <jodit-editor
                    :config="joditConfig"
                    class="mt-3"
                    v-model="object.Value"
                    @input="setValueContractualTerms($event, object)"
                  />
                  <!-- <ckeditor
                    class="mt-3"
                    v-model="object.Value"
                    :read-only="
                      checkQuillDisabled(object.ContractualTermTypeId)
                    "
                    @input="setValueContractualTerms($event, object)"
                  ></ckeditor> -->
                </vs-col>
              </vs-tab>
            </vs-tabs>
            <vs-row>
              <vs-col vs-w="3">
                <vs-checkbox
                  :disabled="blockInputs || changeable"
                  v-model="proposta.BlockChangesInProposal"
                  >Bloquear alterações de valores da Proposta
                </vs-checkbox>
              </vs-col>

              <vs-col vs-w="3">
                <vs-checkbox
                  :disabled="blockInputs || changeable"
                  v-model="proposta.EnableToBigRisk"
                  >Grande risco
                </vs-checkbox>
              </vs-col>

              <vs-col vs-w="3">
                <vs-checkbox
                  :disabled="blockInputs || changeable"
                  v-model="proposta.EnableToMonitoring"
                  >Monitoramento
                </vs-checkbox>
              </vs-col>

              <vs-col vs-w="3">
                <vs-checkbox
                  :disabled="blockInputs || changeable"
                  v-model="proposta.EnableToSteppin"
                  >Retoma de objeto (Step-in)
                </vs-checkbox>
              </vs-col>
            </vs-row>
            <hr class="mt-5 px-8 opacity-25" />

            <vs-row>
              <vs-col vs-w="12" vs-type="flex" vs-justify="end">
                <vs-button
                  @click.native="parcelasModal = true"
                  color="primary"
                  class="mr-2"
                  v-if="proposta.ProposalInstallmentArchiveBilletId != null"
                >
                  <span class="font-semibold pr-2 pl-2">
                    Download do(s) boleto(s)
                  </span>
                </vs-button>
                
                <vs-button
                  @click.native="getEndorsementDocument()"
                  color="primary"
                  class="mr-2"
                  v-if="enableEndorsementDownload"
                >
                  <span class="font-semibold pr-2 pl-2">
                    Download Endosso
                  </span>
                </vs-button>

                <vs-button
                  @click.native="generateContractDraft()"
                  color="primary"
                  class="mr-2"
                >
                  <span class="font-semibold pr-2 pl-2">
                    Download da minuta
                  </span>
                </vs-button>

                <vs-button
                  v-if="!blockInputs"
                  @click.native="atualizarProposta()"
                  color="primary"
                  :disabled="changeable"
                >
                  <span class="font-semibold pr-2 pl-2">
                    Atualizar proposta
                  </span>
                </vs-button>
              </vs-col>
            </vs-row>
          </vs-tab>

          <vs-tab label="Histórico do Motor">
            <historico-motor
              :proposalLogs="proposalEngineExecutionLogs"
            ></historico-motor>
          </vs-tab>

          <vs-tab label="Follow Up">
            <follow-up :proposalObservations="proposalObservations"></follow-up>
          </vs-tab>

          <vs-tab label="Documentos">
            <proposal-documents
              :proposalDocuments="proposalDocuments"
              :proposalDeletedDocuments="proposalDeletedDocuments"
              :documentType="3"
              @loadProposta="loadProposta"
            ></proposal-documents>
          </vs-tab>
        </vs-tabs>
      </div>
    </template>

    <vs-popup title="Aviso" :active.sync="changeableModal">
      <div class="vw-row">
        <div class="vx-col w-full">
          <h5>A proposta não pode ser alterado.</h5>
        </div>
        <div class="vx-col w-full p-1">
          <h6>
            Proposta emitida ou aguardando confirmação da emissão pela
            seguradora.
          </h6>
        </div>
      </div>
    </vs-popup>

    <vs-popup
      class="termos-modal fix-scroll"
      fullscreen
      :button-close-hidden="true"
      title="Condições Particulares"
      :active.sync="particularClausesDialog"
    >
      <ValidationObserver v-slot="{ invalid }">
        <particular-clauses-select
          v-if="
            particularClauses &&
              particularClauses.length &&
              !!particularClausesDialog
          "
          class="mb-10"
          v-model="particularClausesSelect"
          :proposal="proposta"
          :options="particularClauses"
          @proposal-terms-values-change="
            particularClausesProposalTermsValuesChanged
          "
        >
        </particular-clauses-select>

        <vs-row vs-type="flex" vs-justify="flex-end">
          <vs-button
            @click="particularClausesDialog = false"
            class="mr-3"
            color="dark"
          >
            Cancelar
          </vs-button>
          <el-button
            :disabled="invalid"
            type="primary"
            @click="updateParticularClausesInPropose()"
            :loading="loadingButton"
            >Prosseguir</el-button
          >
        </vs-row>
      </ValidationObserver>
    </vs-popup>

    <listar-parcelas-popup
      :parcelasModal="parcelasModal"
      :propostaUniqueId="
        $route.params.propostaUniqueId
          ? $route.params.propostaUniqueId
          : $route.params.proposalType
      "
      @fecharParcelasModal="parcelasModal = false"
      :autoLoad="true"
    />
  </section>
</template>

<script>
import particularClausesInPageMixin from "@/mixins/particularClauses/particularClausesInPageMixin";
import ParticularClausesSelect from "@/components/propostas/ParticularClausesSelect.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { mask, TheMask } from "vue-the-mask";
import axiosInstance from "@/axios";
import * as Enums from "@/utils/enums";

import Breadcrumb from "@/layouts/components/breadcrumb/Breadcrumb.vue";
import DadosFinanceiros from "./components/emissao/DadosFinanceiros.vue";
import HistoricoMotor from "./components/visualizacao/HistoricoMotor.vue";
import FollowUp from "./components/visualizacao/FollowUp.vue";
import Documentos from "./components/Documentos.vue";
import ListarParcelasPopup from "./components/visualizacao/ListarParcelasPopup.vue";
import VisualizarAlteracoes from "@/views/endosso/components/VisualizarAlteracoes.vue";
import ResseguroMapfre from "@/views/propostas/components/emissao/mapfre/ResseguroMapfre.vue";
import CosseguroMapfre from "@/views/propostas/components/emissao/mapfre/CosseguroMapfre.vue";
import ConfiguracaoRestituicao from "@/views/propostas/components/modais/ConfiguracaoRestituicao.vue";

import environment from "@/environment";
// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { Jodit, JoditEditor } from "jodit-vue";
import PerfilHelpers from "@/helpers/PerfilHelpers";


import mapfreProvider from "@/providers/mapfre-provider.js";
import commonProvider from "@/providers/common-provider.js";
import * as Permissoes from "@/utils/permissions.js"

export default {
  directives: { mask },
  components: {
    ParticularClausesSelect,
    ListarParcelasPopup,
    Breadcrumb,
    TheMask,
    HistoricoMotor,
    FollowUp,
    DadosFinanceiros,
    quillEditor,
    "proposal-documents": Documentos,
    VisualizarAlteracoes,
    ResseguroMapfre,
    CosseguroMapfre,
    ConfiguracaoRestituicao   
  },
  mixins: [particularClausesInPageMixin],
  data() {
    return {           
      compareChanges: {},
      StringObservations: false,
      loadingButton: false,
      joditConfig: { language: "pt_br" },
      CreateDate: "",
      StartDate: "",
      EndDate: "",
      StartDateComplementary: "",
      EndDateComplementary: "",
      loaded: false,
      changeable: false,
      parcelasModal: false,
      changeableModal: false,
      endosso: {},
      diffEndosso: [],
      responsavelDataUpdateEndosso: {},
      particularClausesDialog: false,
      particularClausesSelect: [],
      perfilHelpers: PerfilHelpers,
      activeTab: 1,
      proposalClausesTermsValues: null,
      perfilHelpers: PerfilHelpers,
      isMapfre: false,
      permissoesCocorretagemMapfre: [],
      permissoesResseguroMapfre: [],
      permissoesCosseguroMapfre: [],
      tipoResseguroMapfre: null,
      tipoCosseguroMapfre: null,
      resseguroMapfre: {},
      cosseguroMapfre: [],
      endossosToRestituition:[],
      restituitionPermition:false,
      endossosDaProposta:null,      
      configuracaoRestituicaoDialog:false,
      teste:false,
      permissoes: []
    };
  },
  computed: {      
    ...mapGetters("proposta-module", [
      "proposta",
      "proposalObjects",
      "proposalEngineExecutionLogs",
      "proposalObservations",
      "proposalDocuments",
      "proposalDeletedDocuments",
      "proposalTimelineHistory",
      "propostaFinancialData"
    ]),
    statusName() {
      switch (this.proposta.StatusId) {
        case 1:
          return "Cotação";
        case 2:
          return "Em Análise";
        case 3:
          return "Aguardando Emissão";
        case 4:
          return "Aguardando Pagamento";
        case 5:
          return "Emitida";
        case 6:
          return "Aguardando Corretor";
        case 98:
          return "Recusada";
        case 99:
          return "Cancelada";
      }
    },

    allowedHTML() {
      return [environment.CompanyId.Essor].includes(
        (this.$store.getters["auth/userInfo"] || {}).InsuranceId
      );
    },

    proposalTerms() {
      return this.proposalObjects
        .filter(
          term =>
            term.ContractualTermTypeId != 6 && term.ContractualTermTypeId != 8
        )
        .map(x => {
          return { ...x, Value: x.Value.split("\\n").join("<br />") };
        });
    },

    proposalEndorsement() {
      let terms = this.proposalObjects
        .filter(term => term.ContractualTermTypeId == 8)
        .map(x => {
          return { ...x, Value: x.Value.split("\\n").join("<br />") };
        });

      return terms;
    },

    proposalClauses() {
      return this.proposalObjects
        .filter(term => term.ContractualTermTypeId == 6)
        .map(x => {
          return { ...x, Value: x.Value.split("\\n").join("<br />") };
        });
    },

    blockInputs: {
      get() {
        return (
          !this.perfilHelpers.checkPermission([
            this.perfilHelpers.menuFuncionalitiesEnum
              .GERENCIAR_PROPOSTAS_SEGURADORA
          ]) ||
          this.proposta.StatusId == Enums.ProposalStatus.AguardandoPagamento ||
          this.proposta.StatusId == Enums.ProposalStatus.Recusada ||
          this.proposta.StatusId == Enums.ProposalStatus.Cancelada ||
          this.proposta.StatusId == Enums.ProposalStatus.Emitida
        );
      }
    },

    enableEndorsementDownload: {
      get() {
        const sortedTimeline = [...this.proposalTimelineHistory].sort(
          (a, b) => new Date(b.CreateDate) - new Date(a.CreateDate)
        );

        return (
          this.proposta.StatusId == Enums.ProposalStatus.AguardandoEmissao &&
          this.proposta.ProposalTypeId == 2 &&
          sortedTimeline[0].StatusId == 2 &&
          this.permissoes.includes("PermitidoDownloadEndosso")
        );
      }
    },

    podeExibirResseguro() {
      return this.permissoesResseguroMapfre.includes("ExibirResseguro");
    },

    podeExibirCosseguro(){
      return this.permissoesCosseguroMapfre.includes("ExibirCosseguro");
    }
  },
  watch: {
    proposalTimelineHistory(val) {
      if (!val.length || (val[0].SituationId === 6 && val[0].StatusId === 2)) {
        this.changeableModal = true;
        this.changeable = true;
        this.$forceUpdate();
      }
    }
  },
  async mounted() {
    await this.loadProposta()
      .then(() => {
        this.CreateDate = this.$utils.dateToDDMMYYYYHHmm(
          this.proposta.CreateDate
        );
        this.EndDate = this.$utils.dateToDDMMYYYY(this.proposta.EndDate);
        this.StartDate = this.$utils.dateToDDMMYYYY(this.proposta.StartDate);

        if (this.proposta.ComplementaryModality) {
          this.StartDateComplementary = this.$utils.dateToDDMMYYYY(
            this.proposta.ComplementaryStartDate
          );
          this.EndDateComplementary = this.$utils.dateToDDMMYYYY(
            this.proposta.ComplementaryEndDate
          );
        }

        this.loaded = true;
      })
      .catch(error => {
        return this.$router.push({ name: "kanban", params: {} });
      });

    await this.getParticularClauses(this.proposta.ModalityId);
    await this.pegarPermissoesMapfre();
    await this.obterModuloPermissoes();

    this.isMapfre =
      environment.CompanyId.Mapfre ==
      this.$store.state["auth"].userInfo.InsuranceId;
  },
  async created() {
    this.setupEditor();
    this.$appInsights.trackPageView({
      name: "resumo-proposta-visao-seguradora",
      url: window.location.href
    });
    await this.pegarPermissoesMapfre();
    this.checkRestituitionPermition(2)    
  },
  methods: {        
    setupEditor() {
      Jodit.defaultOptions.controls.font.list = {
        "Arial Narrow": "Arial Narrow",
        Calibri: "Calibri",
        "Arial,Helvetica,sans-serif": "Arial",
        "Impact,Charcoal,sans-serif": "Impact",
        "Tahoma,Geneva,sans-serif": "Tahoma",
        "'Times New Roman',Times,serif": "Times New Roman",
        "Verdana,Geneva,sans-serif": "Verdana",
        "Roboto Medium,Arial,sans-serif": "Roboto"
      };
      Jodit.defaultOptions.controls.fontsize.list = {
        12.5: "12.5",
        15: "15",
        18: "18",
        24: "24",
        24: "24",
        36: "36",
        48: "48",
        56: "56",
        70: "70",
        90: "90"
      };
    },
    ...mapActions("proposta-module", [
      "loadProposalInfosByUniqueId",
      "InsertProposalObjets",
      "loadEndorsementInfoByUniqueId"
    ]),
    ...mapActions("endosso-module", ["getCompareChanges", "generateEndorsementDocument"]),
    ...mapActions("propostas-module", ["gerarMinuta"]),
    ...mapActions("proposta-module", [ "getEndossoByPolicyUniqueId"]),
    ...mapMutations("proposta-module", [
      "updateProposalObjects",
      "limparEstado",     
    ]),

    InsertObjets(row) {
      let dados = this.proposalObjects;
      dados.map(term => {
        if (term.Id === row.Id) {
          term.EnableToProposal = !term.EnableToProposal;
        }
      });
    },
    async updateParticularClausesInPropose() {
      this.$vs.loading();
      this.loadingButton = true;
      await this.updateParticularClauses(this.proposta.Id).then(() => {
        this.loaded = false;

        this.loadProposta().then(() => {
          this.loaded = true;
          this.loadingButton = false;
          this.$vs.loading.close();
        });
      });
    },

    setProposalClauses() {
      return (
        (this.contractualTerms || []).filter(item => item.TypeId == 6) || []
      ).map(term => {
        let clause = {
          ...term.ParticularClause,
          ContractualTermId: term.ContractualTermId,
          ContractualTerm: {
            ...term.ContractualTerm,
            JSONTagValue: term.JSONTagValue,
            ContractualTermId: term.ContractualTermId
          }
        };

        return clause;
      });
    },

    loadProposta() {
      return this.$onpoint.loading(async () => {
        try {
          if (this.$route.params.proposalType != "Endosso")
            await this.loadProposalInfosByUniqueId(
              this.$route.params.propostaUniqueId
                ? this.$route.params.propostaUniqueId
                : this.$route.params.proposalType
            ).then(resp => {
              // console.log("Laod Endorsement: ", resp.Endorsement);
              this.endosso = resp.Endorsement;
              this.StringObservations = resp.StringObservations;
              this.loadGetCompareChanges();
            });
          else
            await this.loadEndorsementInfoByUniqueId(
              this.$route.params.propostaUniqueId
                ? this.$route.params.propostaUniqueId
                : this.$route.params.proposalType
            ).then(data => {
              // console.log(
              //   "loadEndorsementInfoByUniqueId: ",
              //   data.StringObservations
              // );
              this.StringObservations = data.StringObservations;
              // console.log("Laod Endorsement: ", data.Endorsement);
              this.endosso = data.Endorsement;
              this.loadGetCompareChanges();
            });

          await this.getParticularClauses(
            this.proposta.ModalityId,
            this.proposta.UniqueId
          );
        } catch (error) {
          this.$onpoint.errorModal(error.response.Message);
          setTimeout(() => {
            this.$router.push({
              name: "propostas-seguradora"
            });
          }, 1500);
        }
      });
    },

    validaDadosProposta(dados) {
      let result = false;
      if (dados.ProposalId == null) {
        result = "Id da proposta";
      }
      if (dados.GracePeriod == null) {
        result = "Período de carência";
      }
      if (dados.InsurancePremium == null) {
        result = "Prêmio da modalidade principal";
      }
      if (dados.ComplementaryModality.Id != 0) {
        if (dados.InsurancePremiumComplementaryModality == null) {
          result = "Prêmio da modalidade complementar";
        }
      }
      if (dados.RiskRate == null || undefined) {
        result = "Taxa de risco";
      }
      if (dados.ComissionValue == null || undefined) {
        result = "Comissão";
      }
      if (dados.BlockChangesInProposal == null || undefined) {
        result = "Bloquear alterações de valores da Proposta";
      }
      if (dados.InsurancePremiumSetToZero == null || undefined) {
        result = "Parâmetro - InsurancePremiumSetToZero";
      }
      if (dados.EnableToBigRisk == null || undefined) {
        result = "Grande risco";
      }
      if (dados.EnableToMonitoring == null || undefined) {
        result = "Monitoramento";
      }
      if (dados.InsurancePremiumSetManually == null || undefined) {
        result = "Parâmetro - InsurancePremiumSetManually";
      }
      if (dados.EnableToSteppin == null || undefined) {
        result = "Retoma de objeto (Step-in)";
      }
      if (dados.ProposalContractualTerms.length <= 0) {
        result = "Objetos da proposta";
      }
      return result;
    },
    atualizarProposta(resetLimit) {
      let proposal = JSON.parse(JSON.stringify(this.proposta));
      let data = {
        ...proposal,
        ProposalObjects: this.allowedHTML
          ? this.proposalObjects
          : this.proposalObjects.map(x => {
              return { ...x, Value: x.Value };
            }),
        ContractualTerms: this.allowedHTML
          ? this.proposalObjects
          : this.proposalObjects.map(x => {
              return { ...x, ContractualTermId: x.Id, Value: x.Value };
            })
      };
      // if (
      //   data.InsurancePremium != this.propostaFinancialData.InsurancePremium
      // ) {
      //   data.InsurancePremium = this.propostaFinancialData.InsurancePremium;
      // } comentado devido PBI8150-Essor - Implementação da Funcionalidade de Restituição Total
      if (
        data.InsurancePremiumPrincipalModality !=
        this.propostaFinancialData.PrincipalModality
      ) {
        data.InsurancePremiumPrincipalModality = this.propostaFinancialData.PrincipalModality;
      }
      if (
        data.InsurancePremiumComplementaryModality !=
        this.propostaFinancialData.ComplementryModality
      ) {
        data.InsurancePremiumComplementaryModality = this.propostaFinancialData.ComplementryModality;
      }
      // if (data.Tax != this.propostaFinancialData.Tax) {
      //   data.Tax = this.propostaFinancialData.Tax;
      // } comentado devido PBI8150-Essor - Implementação da Funcionalidade de Restituição Total
      // if (data.ComissionValue != this.propostaFinancialData.ComissionValue) {
      //   data.ComissionValue = this.propostaFinancialData.ComissionValue;
      // } comentado devido PBI8150-Essor - Implementação da Funcionalidade de Restituição Total
      data.ProposalCoverages.map(Coverage => {
        for (let i = 0; i < this.propostaFinancialData.Coverages.length; i++) {
          if (
            Coverage.Id == this.propostaFinancialData.Coverages[i].CoverageId
          ) {
            // console.log(Coverage.Id, Coverage.Value, this.propostaFinancialData.Coverages[i].CoveragePremium)
            Coverage.Value = this.propostaFinancialData.Coverages[
              i
            ].CoveragePremium;
            console.clear();
          }
        }
      });
      if (!data.ProposalId) {
        data.ProposalId = data.Id;
      }
      if (!data.Coverages) {
        data.Coverages = JSON.parse(
          JSON.stringify(
            this.$store.state["proposta-module"].propostaFinancialData.Coverages
          )
        );
        let array = [];
        data.Coverages.map(x => {
          array.push({
            Value: x.CoveragePremium,
            CoverageId: x.CoverageId,
            CoveragePremium: x.CoveragePremium
          });
        });
        data.Coverages = array;
        // console.log(array, data.Coverages);
      }
      let result = this.validaDadosProposta(data);
      if (result) {
        this.$onpoint.errorModal("Dados inválidos: " + result);
        return;
      }

      if(this.isMapfre){
        if(data.CoBrokers.length < 2 && data.HasCoBrokers) {
          this.$onpoint.errorModal(
            "Quando possuir cocorretagem, o número mínimo de corretores deve ser 2"
          );
          return
        }

        if(data.HasCoBrokers) {
          let codigoQuadroDistribuicaoMapfre = data.CoBrokers[0].CodigoQuadroDistribuicao;

          if (codigoQuadroDistribuicaoMapfre.length === 0) {
            this.$onpoint.errorModal("Preencha o campo Código do quadro de distribuição para prosseguir");
            return
          }

          if(!this.participacaoCocorretagemMapfreAprovada(data.CoBrokers)) {
            this.$onpoint.errorModal("Cocorretagem: O somatório dos percentuais deve ser de 100%");
            return
          }
        }

        if(this.podeExibirCosseguro) {
          data.CoInsuranceUpdateRequest = {
            CoInsuranceType: this.tipoCosseguroMapfre,
            CoInsurancers: this.cosseguroMapfre
          };

          if(data.CoInsuranceUpdateRequest.CoInsuranceType !== 0) {            
            if(this.possuiCamposVaziosCosseguroMapfre(data.CoInsuranceUpdateRequest)){
              this.$onpoint.errorModal("Cosseguro: Todos os campos devem ser preenchidos!");
              return
            }

            if(!this.valoresPercentuaisCosseguroMapfreAprovados(data.CoInsuranceUpdateRequest)) return
          }
        }

        if(this.podeExibirResseguro){
          data.Reinsurance = this.resseguroMapfre;

          if(data.Reinsurance){
            if(this.possuiCamposVaziosResseguroMapfre(data.Reinsurance.Reinsurers)){
              this.$onpoint.errorModal("Resseguro: Todos os campos devem ser preenchidos!");
              return
            }

            if(!this.participacaoResseguroMapfreAprovada(data.Reinsurance)) {
              this.$onpoint.errorModal("Resseguro: O somatório dos percentuais deve ser de 100%");
              return
            }
          }
        }
      }

      let url = data.ProposalTypeId != 2 ? "api/proposal/UpdateProposal": "api/proposal/UpdateProposalEndorsement"
      this.$onpoint.loadingModal("Estamos salvando a proposta", () => {
        return axiosInstance
          .post(url, data)
          .then(response => {
            this.loadProposta();
            this.$vs.notify({
              title: "Sucesso",
              text: "Proposta atualizada com sucesso!",
              color: "success"
            });
          })
          .catch(errors => {
            this.$onpoint.errorModal(errors.response.data.Errors);
          });
      });
    },
    checkQuillDisabled(contractualTermTypeId) {
      return (
        (contractualTermTypeId ==
          Enums.ContractualTermType.CondicoesEspeciais &&
          this.proposta.CompanyId == environment.CompanyId.Excelsior) ||
        this.blockInputs
      );
    },

    setValueContractualTerms(value, item) {
      let proposalObjects = this.$utils.deepClone(this.proposalObjects);

      proposalObjects.forEach(term => {
        if (term.Id == item.Id) term.Value = value;
      });

      this.updateProposalObjects(proposalObjects);
    },

    async generateContractDraft() {
      await this.$onpoint.loading(async () => {
        return await this.gerarMinuta(this.proposta.UniqueId)
          .then(urlToDownload => {
            window.open(urlToDownload, "_self");
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

    async getEndorsementDocument()
    {
      await this.$onpoint.loading(async () => {
        return await this.generateEndorsementDocument(this.proposta.UniqueId)
          .then(urlToDownload => {
            window.open(urlToDownload, "_self");
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

    loadGetCompareChanges() {
      if (this.proposta.ProposalTypeId == 2)
        this.$onpoint.loading(() => {
          return this.getCompareChanges({
            uniqueId: this.$route.params.propostaUniqueId
              ? this.$route.params.propostaUniqueId
              : this.$route.params.proposalType
          }).then(result => {
            if (!this.endosso) {
              this.endosso = result;
            }
            // console.log("loadGetCompareChanges: ", result);
            this.compareChanges = result;
            this.responsavelDataUpdateEndosso = {
              UserName: result.UserOwner.Name,
              ModifiedAt: result.ModifiedAt
            };

            ((result || {}).ChangeTypes || []).forEach(item => {
              if (item.ChangesValues) {
                item.ChangesValues.forEach(element => {
                  this.diffEndosso.push({
                    current: element.Current ? element.Current : "--",
                    origin: element.Previous ? element.Previous : "--",
                    label: element.Name ? element.Name : "Sem Registro",
                    key: element.Name + element.Current
                  });
                });
              } else {
                this.diffEndosso.push({
                  current: "--",
                  origin: "--",
                  label: item.Description,
                  key: item.Description + item.Id
                });
              }
            });
          });
        });
    },

    showParticularClausesDialog() {
      this.particularClausesSelect = this.setProposalClauses();
      this.particularClausesDialog = true;
    },

    particularClausesProposalTermsValuesChanged(proposalTermsValues) {
      this.particularClausesChange = proposalTermsValues || [];
    },

    async pegarPermissoesMapfre() {
      this.permissoesCocorretagemMapfre = [];
      this.permissoesResseguroMapfre = [];
      this.permissoesCosseguroMapfre = [];
      try {
        this.permissoesCocorretagemMapfre = await mapfreProvider.obterPermissoesModulo(Permissoes.Modulo.COCORRETAGEM);
        this.permissoesResseguroMapfre = await mapfreProvider.obterPermissoesModulo(Permissoes.Modulo.RESSEGURO);
        this.permissoesCosseguroMapfre = await mapfreProvider.obterPermissoesModulo(Permissoes.Modulo.COSSEGURO);
      } catch (error) {
        this.$onpoint.errorModal(error.response.data.Errors);
      }
    },

    async obterModuloPermissoes()
    {
      this.permissoes = [];
      try {
        this.permissoes = await commonProvider.obterPermissoesModulo(Permissoes.Modulo.ENDOSSO);
      } catch (error) {
        this.$onpoint.errorModal(error.response.data.Errors);
      }
    },

    popularResseguroMapfre(event) {
      this.resseguroMapfre = event;
    },

    popularTipoResseguroMapfre(event){
      this.tipoResseguroMapfre = event;
    },

    popularCosseguroMapfre(event) {
      this.cosseguroMapfre = event;
    },

    popularTipoCosseguroMapfre(event){
      this.tipoCosseguroMapfre = event;
    },

    camposVazio(value){
      return value === "" || value === null || value === undefined;
    },

    possuiCamposVaziosCosseguroMapfre(cosseguro) {
      for (const cosseguradora of cosseguro.CoInsurancers) {
        if(cosseguro.CoInsuranceType === 1) {
          if (this.camposVazio(cosseguradora.CodeCoInsurance) ||
              this.camposVazio(cosseguradora.PercentParticipation) ||
              this.camposVazio(cosseguradora.PercentBroker) ||
              this.camposVazio(cosseguradora.PercentSpanding) ||
              this.camposVazio(cosseguradora.ProposalId) ||
              this.camposVazio(cosseguradora.CompanyId)) {
            return true;
          }
        } 

        if(cosseguro.CoInsuranceType === 2) {
          if (this.camposVazio(cosseguradora.CodeCoInsurance) ||
              this.camposVazio(cosseguradora.PercentParticipation) ||
              this.camposVazio(cosseguradora.PercentBroker) ||
              this.camposVazio(cosseguradora.PercentSpanding) ||
              this.camposVazio(cosseguradora.LeadPolicyNumber) ||
              this.camposVazio(cosseguradora.NumberEndorsement) ||
              this.camposVazio(cosseguradora.ProposalId) ||
              this.camposVazio(cosseguradora.CompanyId)) {
            return true;
          }
        } 

        if (cosseguradora.CoInsurance.Person) {
          if (this.camposVazio(cosseguradora.CoInsurance.Person.CpfCnpj) ||
              this.camposVazio(cosseguradora.CoInsurance.Person.Name)) {
            return true;
          }
        } else {
          return true;
        }
      }
      return false;
    },

    possuiCamposVaziosResseguroMapfre(resseguradoras) {
      for (const resseguradora of resseguradoras) {
        if (this.camposVazio(resseguradora.Percent) ||
            this.camposVazio(resseguradora.NumberSlip) ||
            this.camposVazio(resseguradora.ContractYear) ||
            this.camposVazio(resseguradora.ProposalId) ||
            this.camposVazio(resseguradora.CompanyId)) {
          return true;
        }
        if (resseguradora.Reinsurer.Person) {
          if (this.camposVazio(resseguradora.Reinsurer.Person.CpfCnpj) ||
              this.camposVazio(resseguradora.Reinsurer.Person.Name)) {
            return true;
          }
        } else {
          return true;
        }
      }
      return false;
    },

    participacaoCocorretagemMapfreAprovada(cocorretores){
      let novoPercentual = 0;
      cocorretores.forEach(cocorretor => {
        novoPercentual += parseFloat(cocorretor.Rate);
      });
      if (novoPercentual !== 100) {
        return false;
      }
      return true
    },

    valoresPercentuaisCosseguroMapfreAprovados(cosseguro){      
      if(cosseguro.CoInsuranceType === 1) {
        let percentSpandingType1 = 0;
        let percentParticipationType1 = 0;
        let percentBrokerType1 = 0;

        cosseguro.CoInsurancers.forEach(cossegurador => {
          percentSpandingType1 += parseFloat(cossegurador.PercentSpanding);
          percentParticipationType1 += parseFloat(cossegurador.PercentParticipation);
          percentBrokerType1 += parseFloat(cossegurador.PercentBroker);
        })

        if(percentSpandingType1 !== 100 || percentParticipationType1 !== 100 || percentBrokerType1 !== 100){
          this.$onpoint.errorModal("Cosseguro: A soma dos percentuais de '% de participação', '% corretor', '% gastos' considerando todos os itens da lista, deve ser igual a 100% para cada um deles separadamente. Verifique os valores e tente novamente!");
          return false
        }
      }
      
      if(cosseguro.CoInsuranceType === 2) {
        let percentSpandingType2 = cosseguro.CoInsurancers[0].PercentSpanding > 0;
        let percentParticipationType2 = cosseguro.CoInsurancers[0].PercentParticipation > 0;
        let percentBrokerType2 = cosseguro.CoInsurancers[0].PercentBroker > 0;
        
        if(!percentSpandingType2 || !percentParticipationType2 || !percentBrokerType2) {
          this.$onpoint.errorModal("Cosseguro: campos '% de participação', '% corretor', '% gastos' devem estar entre 0% e 100%!");
          return false
        }
      }
      return true;
    },

    participacaoResseguroMapfreAprovada(resseguradoras){
      if(resseguradoras && resseguradoras.ReinsuranceType === 2){
        let novoPercentual = 0;
        resseguradoras.Reinsurers.forEach(resseguradora => {
          novoPercentual += parseFloat(resseguradora.Percent);
        });
        if (novoPercentual !== 100) {
          return false;
        }
      }
      return true
    },
       
    async checkRestituitionPermition(modulo){   

      return this.restituitionPermition = await axiosInstance
        .get(`api/Permissao/BuscarPermissoes?Modulo=${modulo}`)
        .then(response => response.data.Response.Acao.includes("HabilitarDividirEndossoRestituicao"))
    }     
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/mixins";

.header-proposal {
  margin: 2rem 0;
}

input.vs__search {
  opacity: 0;
}

.multiselect__content-wrapper {
  width: 97%;
  margin-left: 20px;
}

.multiselect__option {
  background: white;
  color: rgba(var(--vs-primary), 1);
  border-bottom: 1px solid #e8e8e8;
}

.vs-input--input:disabled {
  opacity: 1 !important;
  background: #f1f1f1 !important;
}

.multiselect__option--highlight {
  background: #f1f1f1;
}

.multiselect__option--highlight:after {
  display: none;
}

.multiselect__tags {
  border-radius: 50px !important;
  height: 49.2px;
  padding-top: 10px;
}

.multiselect__placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 100%;
  padding-left: 6px !important;
  padding-top: 0 !important;
}

.multiselect__input {
  font-size: 13px !important;

  @include lg {
    font-size: 16px !important;
  }
}

.visualizar-proposta-tabs {
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.vs-tabs--ul {
  border-bottom: 2px solid #d9d9d9 !important;
  box-shadow: none;
}

.quill-container {
  .ql-toolbar,
  .ql-container {
    border: none !important;
  }

  .ql-disabled > .ql-editor {
    background: #f1f1f1 !important;
    border-radius: 0px 0px 22px 22px !important;
  }
}

.input-the-mask {
  font-size: 1em !important;
}

.tooltip-info .material-icons {
  color: rgba(var(--vs-warning), 1);
  font-size: 0.8em;
}
</style>
<style>
.fix-scroll .vs-popup--content {
  overflow: auto !important;
}
</style>
